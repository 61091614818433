import Axios from 'axios';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import file from '../../../images/svg/file.svg';
import upload from '../../../images/svg/upload.svg';
import '../style.css';

const Coord = () => {

  /* const api = 'https://pf-api.prouniversidade.com.br'; */
  // const api = 'http://localhost/pro-floripa/api';

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    curriculum: "",
  })

  const [fileState, setFileState] = useState({
    name: "",
    data: "",
    size: "",
    type: "",
    extension: "",
  })

  useEffect(() => {
    updateDisabled();
  }, [state, fileState]);

  const handleInputChange = (event) => {
    state[event.target.name] = event.target.value;
    setState({ ...state });
  }

  const updateDisabled = (meth = null) => {
    meth && console.log(meth, formIsValid());
    setDisabled(formIsValid() ? false : true);
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const base64 = await convertBase64(file);
      setFileState({
        name: file.name,
        data: base64,
        size: file.size,
        type: file.type,
        extension: file.name.split('.').pop(),
      })

    } else {

      setFileState({
        name: "",
        data: "",
        size: "",
        type: "",
        extension: "",
      })
    }
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const formIsValid = () => {
    return state.name && state.email && state.phone && fileState.data;
  }

  const handleOnSubmit = async event => {
    event.preventDefault();
  
    if (formIsValid()) {
      setLoading(true);
  
      try {
        const response = await fetch('https://formspree.io/f/mvgorapv', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...state,
            area: 3,
            file: fileState
          })
        });
  
        if (response.ok) {
          navigate('/thanks-team');
        } else {
          console.error('Erro ao enviar o formulário', response);
          alert('Erro ao enviar formulário. Tente novamente em instantes');
        }
      } catch (err) {
        console.error('Erro ao enviar o formulário', err);
        alert('Erro ao enviar formulário. Tente novamente em instantes');
      } finally {
        setLoading(false);
      }
    }
  };
  

  return (
    <div>
      <form onSubmit={handleOnSubmit}>
        <div className="grid md:grid-cols-2 gap-5">
          <div className="grid-item">
            <div className="mb-5">
              <input
                name="name"
                required
                value={state.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                type="text"
                placeholder="Nome completo" />
            </div>
          </div>
          <div className="grid-item">
            <div className="mb-5">
              <InputMask
                name="phone"
                required
                mask="(99) 99999-9999"
                maskChar={null}
                value={state.phone}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                type="text"
                placeholder="Celular" />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <input
            name="email"
            required
            value={state.email}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
            type="email"
            placeholder="E-mail" />
        </div>

        <div className="relative mt-3">
          <span className="font-bold">Curriculum <span>(PDF)</span></span>
          <input
            type="file"
            name="curriculum"
            id="curriculum"
            value={state.curriculum}
            onChange={handleFileChange}
            className='w-full h-full absolute top-0 right-0 bottom-0 left-0 cursor-pointer opacity-0' />

          <div className="file-dummy">
            {fileState.name !== "" &&
              <div className="w-full bg-accent border-dashed border-4 border-accent-700 text-center text-white font-bold py-5 ">
                <img className="mb-2 mx-auto h-15" src={file} alt="upload" /> <br />
                {fileState.name}
                <div className="small">{fileState.size}</div>
              </div>
            }
            {fileState.name === "" &&
              <div className="w-full bg-primary border-dashed border-4 border-primary-700 text-center text-white font-bold py-5">
                <img className="mb-2 mx-auto h-15" src={upload} alt="upload" /> <br />
                Arraste ou selecione o arquivo
              </div>
            }
          </div>
        </div>

        {!loading &&
          <button disabled={disabled} className='w-full bottom-3 mx-auto mt-10 bg-accent text-primary-400 py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden disabled:bg-gray-200 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none'>
            Finalizar cadastro
          </button>
        }

        {loading &&
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-15 w-15 border-b-2 border-primary"></div>
          </div>
        }

      </form>
    </div>
  )
}

export default Coord
