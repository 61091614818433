import React from 'react';
import styled from 'styled-components';
import black2024 from '../../../images/svg/black2024.svg';
import blackImage2024 from '../../../images/svg/blackImage2024.svg';
import blackText2024 from '../../../images/svg/blackText2024.svg';

const BannerContainer = styled.div`
  min-height: calc(100vh - 56px);
  background-image: url(${black2024});
`;

export const BannerBlack2024 = () => {
  return (
    <BannerContainer className="w-full md:px-15 px-5 py-5 relative bg-cover bg-center bg-no-repeat
      flex flex-col items-center gap-20 justify-center z-99 raleway">
      <img
        className="hidden md:block absolute left-8 bottom-0 h-5/6"
        src={blackImage2024}
        alt="black November"
      />
      <div className="absolute h-full right-10 top-10">
        <img
          className="h-4/6 px-10"
          src={blackText2024}
          alt="black November"
        />
        <a
          href="https://cursinhopro.com.br/blacknovember/"
          className="flex flex-col text-center text-white p-2 m-4 bg-primary-600 font-bold text-3xl rounded-3xl hover:bg-primary-400 transition-all mt-5"
        >
          CONFIRA AGORA!
        </a>
      </div>
    </BannerContainer>
  );
};
