import { Link } from "gatsby";
import axios from 'axios';
import { navigate } from 'gatsby';
import React, { useState }  from "react";
import { useModal } from 'react-hooks-use-modal';
import about from "../../../images/svg/about.svg";
import extensivo from "../../../images/svg/courses/extensivo.svg";
import intensivo from "../../../images/svg/courses/intensivo.svg";
import product1 from "../../../images/svg/courses/product1.svg"
import smart from "../../../images/svg/courses/smart.svg";
import semiextensivo from "../../../images/svg/courses/semiextensivo.svg";
import BlackLogo from '../../../images/svg/black-logo-white.svg';
import Black2024 from '../../../images/svg/black2024.png'
import BlackLogoModal from '../../../images/svg/black-logo.svg'
import ModalRegister from "../../Shared/Modal";
import ModalRegisterBlack from "../../Shared/ModalBlack";
import modal from '../../../images/svg/modal.svg';
import InputMask from "react-input-mask";


const Courses = () => {

  const [sendMail, setSendMail] = useState(false)

  /* const api = 'https://pf-api.prouniversidade.com.br'; */
  /*  const api = 'http://localhost/pro-floripa/api'; */

  const [Modal, open, close] = useModal('modal', {
    preventScroll: true,
    closeOnOverlayClick: false
  });

  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
  })

  const handleInputChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const createContact = async (name, email, phone) => {
    const contactData = {
      name,
      email,
      phone,
    };

    try {
      const response = await axios.post(`https://api.pipedrive.com/v1/persons?api_token=25103e88b2dd2336b72be7c30bec8af7b10909c2`, contactData);
      return response.data.data.id; // Retorna o ID do contato criado
    } catch (error) {
      console.error("Erro ao criar contato no Pipedrive:", error.response.data);
      return null;
    }
  };

  const createDeal = async (title, value, currency, personId) => {
    const dealData = {
      title, // O título do negócio agora é passado como um parâmetro
      value,
      currency,
      person_id: personId,
      stage_id: 1, // ID da etapa do funil onde o negócio será criado
    };

    try {
      const response = await axios.post(`https://api.pipedrive.com/v1/deals?api_token=25103e88b2dd2336b72be7c30bec8af7b10909c2`, dealData);
      console.log("Negócio criado com sucesso:", response.data);
    } catch (error) {
      console.error("Erro ao criar negócio no Pipedrive:", error.response.data);
    }
  };

  const handleOnSubmit = async event => {
    event.preventDefault();

    const personId = await createContact(state.name, state.email, state.phone);
    if (personId) {
      await createDeal(state.name, 0, "USD", personId); // O nome do negócio agora é o mesmo que o nome do formulário
      setSendMail(true); // Formulário enviado com sucesso
    } else {
      console.error("Erro ao criar contato, não foi possível criar o negócio.");
    }

/*     let c = "black";
    axios.post(`${api}/pipedrive`, { ...state })
      .then(res => {
        setSendMail(res.data.data.deal.success)
        //close();
      })
      .catch(err => {
        console.error('Erro ao enviar formulário', err);
        console.log(err)
        //close();
      }); */
  }


  return (
    <div id="courses" className="bg-primary py-20 relative w-full overflow-hidden">
      <div className="text-4xl text-white text-center font-bold mb-5 raleway">
        Escolha seu curso
      </div>

      <div className="flex flex-wrap md:flex-nowrap md:gap-10 xl:gap-10 justify-center md:px-15 z-99">
        {/* extensivo */}
{/*         <div className="md:flex-1 border-4 border-accent-300 order-1 md:order-2 max-w-lg bg-white shadow-md rounded-xl m-4 z-99 hvr-grow relative">
          <Link to="/cursos/extensivo" className='h-full'>
            <div className="p-8 h-full">
              <div className='md:flex flex-col justify-center h-full mb-24'>
                <img className="mx-auto"  src={extensivo} alt="extensivo" />
                <div className="w-3/4 mx-auto absolute bottom-10 left-0 right-0 text-center rounded-full bg-accent text-primary-400 text-2xl font-semibold px-4 py-1">Matrículas abertas</div>
                <div className='mb-12'>
                  <div className="text-4xl text-primary font-bold text-center">Extensivo 2024</div>
                  <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                  Está procurando uma revisão completa com conteúdos voltados para ENEM e vestibulares? Aqui é seu lugar! Perfeito para você, no ritmo apropriado que você precisa.
                  </div>
  
                </div>
              </div>
            </div>
          </Link>
        </div>  */}

        {/* semi 2 */}
{/*         <div className="md:flex-1 border-4 border-accent-300 order-1 md:order-2 max-w-lg bg-white shadow-md rounded-xl m-4 z-99 hvr-grow relative">
          <Link to="/cursos/semiextensivo" className="h-full">
            <div className="p-8 h-full">
              <div className="md:flex flex-col justify-center h-full mb-24">
                <img
                  className="mx-auto"
                  src={semiextensivo}
                  alt="Semiextensivo"
                />
                <div className="mb-12">
                  <div className="text-4xl text-primary font-bold text-center">
                    Semiextensivo
                  </div>
                  <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                  Pensando no melhor para seu aprendizado. Uma preparação
                  completa, detalhada, focada e eficiente, para você alcançar
                  seu objetivo e conquistar sua vaga na universidade.
                  </div>
                  <div className="w-3/4 mx-auto absolute bottom-10 left-0 right-0 text-center rounded-full bg-accent text-primary-400 text-2xl font-semibold px-4 py-1">
                    Matrículas abertas
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div> */}


        {/* Semiextensivo Smart */}
{/*         <div className="md:flex-1 border-4 border-accent-300 order-1 md:order-2 max-w-lg bg-white shadow-md rounded-xl m-4 z-99 hvr-grow relative">
          <Link to="/cursos/semiextensivosmart" className='h-full'>
            <div className="p-8 h-full">
              <div className='md:flex flex-col justify-center h-full mb-24'>
                <img className="mx-auto"  src={smart} alt="extensivo" />
                <div className="w-3/4 mx-auto absolute bottom-10 left-0 right-0 text-center rounded-full bg-accent text-primary-400 text-2xl font-semibold px-4 py-1">Matrículas abertas</div>
                <div className='mb-12'>
                  <div className="text-4xl text-primary font-bold text-center">Semiextensivo Smart</div>
                  <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                  Está procurando uma revisão completa com conteúdos voltados para ENEM e vestibulares? Aqui é seu lugar! Perfeito para você, no ritmo apropriado que você precisa.
                  </div>
  
                </div>
              </div>
            </div>
          </Link>
        </div> */} 

        {/* smart */}
{/*         <div className="md:flex-1 border-4 border-accent-300 order-1 md:order-2 max-w-lg bg-white shadow-md rounded-xl m-4 z-99 hvr-grow relative">
          <Link to="/cursos/smart" className="h-full">
            <div className="p-8 h-full">
              <div className="md:flex flex-col justify-center h-full mb-24">
                <img className="mx-auto" src={smart} alt="Extensivo Smart" />
                <div className="mb-12">
                  <div className="text-4xl text-primary font-bold text-center">
                    Extensivo Smart
                  </div>
                  <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                    Está procurando uma revisão do ensino médio com conteúdos
                    voltados para ENEM e vestibulares? Aqui é seu lugar!
                    Perfeito para você, no ritmo apropriado que você precisa.
                  </div>
                  <div className="w-3/4 mx-auto absolute bottom-10 left-0 right-0 text-center rounded-full bg-accent text-primary-400 text-2xl font-semibold px-4 py-1">
                    Matrículas abertas
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>  */}

        {/* Revisa ENEM */}
  {/*       <div className="md:flex-1 border-4 border-accent-300 order-1 md:order-2 max-w-lg bg-white shadow-md rounded-xl m-4 z-99 hvr-grow relative">
          <Link to="/cursos/revisaenem" className='h-full'>
            <div className="p-8 h-full">
              <div className='md:flex flex-col justify-center h-full mb-24'>
                <img className="mx-auto"  src={intensivo} alt="Revisa Enem" />
                <div className="w-3/4 mx-auto absolute bottom-10 left-0 right-0 text-center rounded-full bg-accent text-primary-400 text-2xl font-semibold px-4 py-1">Matrículas abertas</div>
                <div className='mb-12'>
                  <div className="text-4xl text-primary font-bold text-center">Revisa ENEM</div>
                  <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                  Precisa de uma revisão intensificada e focada para o ENEM? 
                  Esse é o curso certo para você!
                  </div>
  
                </div>
              </div>
            </div>
          </Link>
        </div>  */}

        {/* Revisa UFSC */}
        <div className="md:flex-1 border-4 border-accent-300 order-1 md:order-2 max-w-lg bg-white shadow-md rounded-xl m-4 z-99 hvr-grow relative">
          <Link to="/cursos/revisaufsc" className='h-full'>
            <div className="p-8 h-full">
              <div className='md:flex flex-col justify-center h-full mb-24'>
                <img className="mx-auto"  src={semiextensivo} alt="Intensivo" />
                <div className="w-3/4 mx-auto absolute bottom-10 left-0 right-0 text-center rounded-full bg-accent text-primary-400 text-2xl font-semibold px-4 py-1">Matrículas abertas</div>
                <div className='mb-12'>
                  <div className="text-4xl text-primary font-bold text-center">Revisa UFSC</div>
                  <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                  Não conseguiu se preparar durante o ano para o vestibular da UFSC? 
                  Venha para o nosso Revisa UFSC. Preparação intensiva para o vestibular.
                  </div>
  
                </div>
              </div>
            </div>
          </Link>
        </div> 
        {/* Matrículas 2024 */}
{/*         <div className="md:flex-1 border-4 border-accent-300 order-1 md:order-2 max-w-lg bg-white shadow-md rounded-xl m-4 z-99 hvr-grow relative " onClick={open}>
          <div className='h-full'>
            <div className="p-8 h-full">
              <div className='md:flex flex-col justify-center h-full mb-24'>
                <img className="mx-auto" src={product1} alt="Extensivo" />
                <div className="w-3/4 mx-auto absolute bottom-10 left-0 right-0 text-center rounded-full bg-accent text-primary-400 text-2xl font-semibold px-4 py-1">Cadastre-se já!</div>
                <div className='mb-12'>
                  <div className="text-4xl text-primary font-bold text-center">Matrículas 2025</div>
                  <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                  Cadastre-se para receber as informações dos nossos cursos de 2025 em primeira mão com descontos especiais.
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        </div>  */}

        {/* Black Friday */}


        <div id="modal">
          <Modal>
            {!sendMail && 
            <div className="max-w-full mx-5 max-h-full bg-white shadow-md py-10 px-10 relative rounded-xl">
              <img className="mx-auto hidden md:block md:h-30" src={modal} alt="Cadastro" />
              <div className="text-2xl text-gray-600 text-center py-5">
                Preencha os dados para ser VIP!
              </div>

              <form className="form md:w-96" onSubmit={handleOnSubmit}>
                <div className="flex flex-col pb-3">
                  <label className="pb-2 font-semibold" htmlFor="name">Nome completo:</label>
                  <input
                    id="name"
                    name="name"
                    value={state.name}
                    onChange={handleInputChange}
                    placeholder="Digite seu nome" className="rounded border-yellow-400" type="text" required />
                </div>
                <div className="flex flex-col pb-3">
                  <label className="pb-2 font-semibold" htmlFor="email">E-mail:</label>
                  <input
                    id="email"
                    name="email"
                    value={state.email}
                    onChange={handleInputChange}
                    placeholder="Digite seu email" className="rounded border-yellow-400" type="email" required />
                </div>
                <div className="flex flex-col pb-3">
                  <label className="pb-2 font-semibold" htmlFor="phone">Celular:</label>
                  <InputMask
                    mask="(99) 99999-9999"
                    maskChar={null}
                    value={state.phone}
                    onChange={handleInputChange}
                    id="phone"
                    name="phone"
                    placeholder="Digite seu número de celular" className="rounded border-yellow-400" type="text" />
                </div>

                <button type="submit" className='w-full bottom-3 mx-auto mt-5 bg-yellow-400 text-black py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden'>
                  PARTICIPAR!
                </button>
              </form>

              <button className="rounded-full bg-white text-black px-2 font-semibold text-2xl absolute -top-3 -right-3" onClick={close}>x</button>
            </div>
            }
            {sendMail &&
            <div className="max-w-full mx-5 max-h-full bg-white shadow-md py-10 px-10 relative rounded-xl">
              <img className="mx-auto hidden md:block md:h-30" src={modal} alt="Cadastro" />
              <div className="text-2xl text-center py-5">
                Parabéns pelo cadastro! <br /> 
                Você está na Lista VIP
              </div>
              <div className="text-2xl text-center py-5">
              Em até 48h nossa equipe <br /> 
              entrará em contato para <br />
              lhe passar todas as informações. 

              </div>
              <div className="text-2xl text-center py-5">
                Mas caso queira tirar alguma dúvida, <br /> 
                estaremos à disposição no telefone (48)98811-1499.
              </div>
              <button type="submit" className='w-full bottom-3 mx-auto mt-5 bg-yellow-400 text-black py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden' onClick={close}>
                Fechar
              </button>

              <button className="rounded-full bg-white text-black px-2 font-semibold text-2xl absolute -top-3 -right-3" onClick={close}>x</button>
            </div>
            }
          </Modal>
          </div>

{/*           <div id="modal">
          <Modal>
            {!sendMail && 
            <div className="max-w-full mx-5 max-h-full bg-white shadow-md py-10 px-10 relative rounded-xl">
              <img className="mx-auto hidden md:block md:h-30" src={modal} alt="Cadastro" />
              <div className="text-2xl text-gray-600 text-center py-5">
                Preencha os dados para ser VIP!
              </div>

              <form className="form md:w-96" onSubmit={handleOnSubmit}>
                <div className="flex flex-col pb-3">
                  <label className="pb-2 font-semibold" htmlFor="name">Nome completo:</label>
                  <input
                    id="name"
                    name="name"
                    value={state.name}
                    onChange={handleInputChange}
                    placeholder="Digite seu nome" className="rounded border-yellow-400" type="text" required />
                </div>
                <div className="flex flex-col pb-3">
                  <label className="pb-2 font-semibold" htmlFor="email">E-mail:</label>
                  <input
                    id="email"
                    name="email"
                    value={state.email}
                    onChange={handleInputChange}
                    placeholder="Digite seu email" className="rounded border-yellow-400" type="email" required />
                </div>
                <div className="flex flex-col pb-3">
                  <label className="pb-2 font-semibold" htmlFor="phone">Celular:</label>
                  <InputMask
                    mask="(99) 99999-9999"
                    maskChar={null}
                    value={state.phone}
                    onChange={handleInputChange}
                    id="phone"
                    name="phone"
                    placeholder="Digite seu número de celular" className="rounded border-yellow-400" type="text" />
                </div>

                <button type="submit" className='w-full bottom-3 mx-auto mt-5 bg-yellow-400 text-black py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden'>
                  PARTICIPAR!
                </button>
              </form>

              <button className="rounded-full bg-white text-black px-2 font-semibold text-2xl absolute -top-3 -right-3" onClick={close}>x</button>
            </div>
            }
            {sendMail &&
            <div className="max-w-full mx-5 max-h-full bg-black shadow-md py-10 px-10 relative rounded-xl">
              <img className="mx-auto hidden md:block md:h-30" src={modal} alt="Cadastro" />
              <div className="text-2xl text-white text-center py-5">
                Parabéns pelo cadastro! <br /> 
                Você está na Lista VIP para receber <br /> 
                os descontos exclusivos da nossa Black November.
              </div>
              <div className="text-2xl text-white text-center py-5">
                Aguarde nosso contato!
              </div>
              <div className="text-2xl text-white text-center py-5">
                Mas caso queira tirar alguma dúvida, <br /> 
                estaremos à disposição no telefone (48)98811-1499.
              </div>
              <button type="submit" className='w-full bottom-3 mx-auto mt-5 bg-yellow-400 text-black py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden' onClick={close}>
                Fechar
              </button>

              <button className="rounded-full bg-white text-black px-2 font-semibold text-2xl absolute -top-3 -right-3" onClick={close}>x</button>
            </div>
            }
          </Modal>
          </div> */}

          <div className="md:flex-1 border-4 border-accent-500 order-1 md:order-2 max-w-lg bg-black shadow-md rounded-xl m-4 z-99 hvr-grow relative">
              <div className="p-8 h-full">
                <div className="md:flex flex-col justify-center h-full mb-24">
                  <img className="mx-auto mb-10" src={Black2024} alt="Extensivo Smart" />
                  <div className="text-4xl text-white font-bold text-center">Matrículas 2025</div>
                  <div className="mb-12">
                  
                    <div className="text-lg font-semibold text-center text-white leading-4">
                    Curso pré-vestibular com valores jamais vistos!<br />
                      <strong>OPORTUNIDADE ÚNICA</strong>
                    </div>
                  </div>
                  
                </div>
              </div>
              <button>
                <a href="https://cursinhopro.com.br/blacknovember/" className="w-3/4 mx-auto absolute bottom-10 left-0 right-0 text-center rounded-full bg-primary text-white text-2xl font-semibold px-4 py-1">
                MATRICULE-SE JÁ!
                </a>
              </button>
          </div>
        
{/*         <div className="md:flex-1 order-3 max-w-lg bg-white shadow-md rounded-xl m-4 z-99 md:mt-20 hvr-grow relative">
          <Link to="/cursos/extensivo" className='h-full'>
            <div className="p-8">
              <div className='mb-24'>
                <img className="mx-auto h-40" src={extensivo} alt="Extensivo" />
                <div className="text-3xl text-primary font-bold text-center">Extensivo 2023</div>
                <div className="text-lg font-semibold text-center text-gray-500 leading-4">
                  Está procurando uma revisão completa com conteúdos voltados para ENEM e vestibulares? Aqui é seu lugar! Perfeito para você, no ritmo apropriado que você precisa.
                </div>
                <div className="w-3/4 mx-auto absolute bottom-10 left-0 right-0 text-center rounded-full bg-accent text-primary-400 text-2xl font-semibold px-4 py-1">Matrículas abertas</div>
              </div>
            </div>
          </Link>
        </div> 
 */}
      </div>

      <img
        className="absolute overflow-hidden top-5 md:max-h-full max-w-full right-3 z-10"
        src={about}
        alt="Sobre"
      />
    </div>
  );
};

export default Courses;
