import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import imageBanner8 from "../../../images/svg/banner/image-banner8.svg";
import imageBanner11 from "../../../images/svg/banner/image-banner11.svg";
import imageBanner12 from "../../../images/svg/banner/image-banner12.png";
import bannerText4 from "../../../images/svg/banner/banner-text4.png"

const BannerContainer = styled.div`
  min-height: calc(100vh - 56px);
  background-color: rgb(255 207 0/ 85%);
`;

const BannerTextContainer = styled.div`
  width: 90%; // Defina a largura inicial desejada para telas menores
  @media (min-width: 640px) {
    width: 40%; // Ajuste para telas médias (md)
  }
  @media (min-width: 768px) {
    width: 50%; // Ajuste para telas grandes (lg)
  }
  @media (min-width: 1024px) {
    width: 60%; // Ajuste para telas grandes (lg)
  }
  @media (min-width: 1280px) {
    width: 80%; // Ajuste para telas grandes (lg)
  }
  @media (min-width: 1536px) {
    width: 90%; // Ajuste para telas extra grandes (xl)
  }
`;

export const BannerRevisaEnem = () => {

    const data = useStaticQuery(
        graphql`
          query {
            img: file(relativePath: { eq: "aprovadasBannerRevisa.png",  }) {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF],
                  quality: 100,
                )
              }
            }
          }
        `);
    
    const aprovadosImg = getImage(data.img.childImageSharp.gatsbyImageData);

    return (
        <BannerContainer className="w-full md:px-15 px-5 py-5 relative bg-cover bg-center bg-no-repeat z-90 raleway">
            <img className="absolute hidden left-0  top-1/2 md:flex xl:left-0 -z-1" src={imageBanner12} alt="Cursinho Pró" /> 
            <img className="absolute hidden right-0 top-40 transform -translate-y-1/4 md:flex xl:right-0 -z-1" src={imageBanner8} alt="Cursinho Pró" />            
            <div className="absolute hidden md:flex md:-right-20 xl:right-0 md:-bottom-10 xl:bottom-0 -z-1">
            <GatsbyImage className='md:h-4/5 xl:h-full' image={aprovadosImg} alt="img" />
            </div>
            <div className='absolute left-0 top-10 xl:top-30 md:left-10 lg:left-20 xl:left-40 z-90'>
                <BannerTextContainer>
                    <img src={bannerText4} alt="Cursinho Pró" />
                </BannerTextContainer>
                <div className="flex text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-center font-extrabold mt-5 leading-tight text-indigo-600 md:leading-normal">
                  Seu ENEM <span className="bg-white p-1 md:p-2 rounded inline-block" style={{ lineHeight: '1', verticalAlign: 'middle' }}>começa agora!</span>
                </div>
                <div className="text-center pt-10 w-full">
                  <a
                  href="#courses"
                  className="w-full md:w-auto cursor-pointer bottom-3 mt-5 bg-warn-500 p-4 px-10 rounded-lg text-2xl md:text-6xl text-white font-bold overflow-hidden 
                          hover:bg-warn-700 hover:shadow-lg hover:scale-105 transition-all duration-300 ease-in-out"
                  >
                  Sua nota náxima começa aqui!
                  </a>
                </div>
            </div>

        </BannerContainer>
    ) 

    

}