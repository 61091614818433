import React from 'react';
import styled from 'styled-components';

import bgSVG from '../../../images/svg/black-bg.svg';
import blackLogoSvg from '../../../images/svg/black-logo.svg';

const BannerContainer = styled.div`
  min-height: calc(100vh - 56px);
  background-image: url(${bgSVG});
`;

export const BannerBlack = () => {

  return (
    <BannerContainer className="w-full md:px-15 px-5 py-5 relative bg-cover bg-center bg-no-repeat
    flex flex-col items-center gap-10 justify-center z-99 raleway">
      <img className='md:max-w-2xl w-full mx-12' src={blackLogoSvg} alt="black November" />
      <a href="https://cursinhopro.com.br/blacknovember/" className=" text-center bg-bf py-2 px-12 mx-12 font-bold text-3xl rounded-3xl md:w-3/5 w-full hover:bg-yellow-500 transition-all">CONFIRA AGORA!</a>
    </BannerContainer>
  );
}
