import React from 'react';
import Slider from 'react-slick';

import { BannerRaioX2024 } from './BannerRaioX2024';
import { BannerSimuladoBolsas2024 } from './BannerSimuladoBolsas2024';
import { BannerRevisaEnem } from './BannerRevisaEnem';
import { BannerBlack2024 } from './BannerBlack2024';
import { BannerBlack } from './BannerBlack';
import { BannerRevisaUFSC } from './BannerRevisaUFSC';



const Banner = () => {
  
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    dots: true,
    dotsClass: 'slick-dots z-9999'
  };

  return (

    <Slider {...settings}>
      <div>
        <BannerBlack2024/>
      </div>
      <div>
        <BannerRevisaUFSC />
      </div>


    </Slider >
  )
}

export default Banner