import axios from 'axios';
import React, { useState } from 'react'
import InputMask from 'react-input-mask';

const Contact = () => {

  const api = 'https://pf-api.prouniversidade.com.br';
  /* const api = 'http://localhost/pro-floripa/api'; */

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: '',
    status: ''
  });
  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  })

  const handleInputChange = event => {
    state[event.target.name] = event.target.value;
    setState({ ...state })
  }

  const handleOnSubmit =async event => {
    event.preventDefault()
    setLoading(true);

    try {
      const response = await fetch('https://formspree.io/f/mvgorapv', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(state)
      });

      if (response.ok) {
        showMessage("Recebemos sua dúvida. Entraremos em contato o mais rápido possível", "green")
        setLoading(false)
      } else {
        showMessage("Erro ao enviar mensagem. Tente novamente em instantes", 'red')
        console.error('Erro ao enviar o formulário', response);
      }
    } catch (err) {
      showMessage("Erro ao enviar mensagem. Tente novamente em instantes", 'red')
      console.error('Erro ao enviar o formulário', err);
      setLoading(false)
    }


/*     axios.post(`${api}/contactForm`, { ...state, campaign: 'contact' })
      .then(res => {
        showMessage("Recebemos sua dúvida. Entraremos em contato o mais rápido possível", "green")
        setLoading(false)
        setState({
          name: "",
          email: "",
          phone: "",
          message: ""
        })
      })
      .catch(err => {
        showMessage("Erro ao enviar mensagem. Tente novamente em instantes", 'red')
        setLoading(false)
      }); */
  }

  const showMessage = (message, status = 'green') => {
    setMessage({ message, status })

    setTimeout(() => {
      setMessage({
        message: '',
        status: ''
      })
    }, 5000)
  }

  return (
    <div>
      <form onSubmit={handleOnSubmit}>
        <div className="flex flex-wrap">
          <div className="px-3 py-3 w-full md:w-2/3">
            <input
              name="name"
              required
              value={state.name}
              onChange={handleInputChange}
              className="w-full border rounded-2xl border-primary"
              type="text"
              placeholder="Nome completo" />
          </div>
          <div className="px-3 py-3 w-full md:w-1/3">
            <InputMask
              name="phone"
              required
              mask="(99) 99999-9999"
              maskChar={null}
              value={state.phone}
              onChange={handleInputChange}
              className="w-full border rounded-2xl border-primary"
              type="text"
              placeholder="Celular" />
          </div>
          <div className="px-3 py-3 w-full">
            <input
              name="email"
              required
              value={state.email}
              onChange={handleInputChange}
              className="w-full border rounded-2xl border-primary"
              type="email"
              placeholder="E-mail" />
          </div>
          <div className="px-3 py-3 w-full">
            <textarea
              name="message"
              required
              value={state.message}
              onChange={handleInputChange}
              className="w-full border rounded-2xl border-primary"
              type="text"
              placeholder="Mensagem" />
          </div>
        </div>

        {message.message &&
          <div className={`bg-${message.status}-500 text-white px-6 py-4 border-0 rounded relative mb-4 flex w-full`}>
            <span className="text-xl inline-block mr-5 align-middle">
              <i className="fas fa-bell" />
            </span>
            <span className="inline-block align-middle mr-8">
              {message.message}
            </span>
          </div>
        }

        {!loading &&
          <button type="submit" className='w-full bottom-3 mx-auto mt-5 bg-accent text-primary-400 py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden'>
            Enviar mensagem
          </button>
        }

        {loading &&
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-15 w-15 border-b-2 border-primary"></div>
          </div>
        }
      </form>
    </div>
  )
}

export default Contact
