import { navigate } from "gatsby";
import React, { useState } from "react";
import { useEffect } from "react";

import Content from "../components/Home/Content";
import Location from "../components/Home/Location";
import Layout from "../components/Layout";
import {
  extensivo,
  intensivo,
  semiextensivo,
  semiextensivosmart,
  smart,
  revisaenem,
  revisaufsc
} from "../data/pricing/pricing";
import extensivoSvg from "../images/svg/courses/extensivo-xl.svg";
import intensivoSvg from "../images/svg/courses/intensivo-xl.svg";
import semiextensivoSvg from "../images/svg/courses/semiextensivo-xl.svg";
import smartSvg from "../images/svg/courses/smart-xl.svg";

const Pricing = ({ location }) => {
  const courseParam = new URLSearchParams(location.search).get("p");

  const [course, setCourse] = useState(revisaenem);
  const [courseSelect, setCourseSelect] = useState("revisaenem");

  const [city, setCitySelect] = useState("floripa");

  const [mode, setModeSelect] = useState("presencial");
  const [modeOptions, setModeOptions] = useState(Object?.keys(revisaenem.floripa));

  const [period, setPeriodSelect] = useState("vespertino");
  const [periodOptions, setPeriodOptions] = useState(
    Object?.keys(revisaenem.floripa.presencial)
  );

  const [installment, setInstallmentSelect] = useState(
    Object?.keys(revisaenem.floripa.presencial.matutino)[
      Object?.keys(revisaenem.floripa.presencial.matutino).length - 1
    ]
  );
  const [installmentOptions, setInstallmentOptions] = useState(
    Object?.keys(revisaenem.floripa.presencial.matutino)
  );

  const [periodLabel, setPeriodLabel] = useState(revisaenem.floripa);
  const [user] = useState({ ...location.state });

  useEffect(() => {
    handleCourse(courseParam);
  }, [courseParam]);

  useEffect(() => {
    handleCity("floripa");
  }, [course]);

  const handleCourse = (course) => {
    switch (course?.toLowerCase()) {
      case "revisaenem":
        setCourse(revisaenem);
        setCourseSelect("revisaenem");
        break;
      case "revisaufsc":
        setCourse(revisaufsc);
        setCourseSelect("revisaufsc");
        break;
      default:
        break;
    }
  };

  const handleCity = (city) => {
    setCitySelect(city);

    const modeKeys = Object?.keys(course[city] || {});
    setModeOptions(modeKeys);
    handleMode(modeKeys[0], city);
  };

  const handleMode = (mode, cityOpt = city) => {
    setModeSelect(mode);

    const periodKeys = Object?.keys(course[cityOpt][mode] || {});
    setPeriodOptions(periodKeys);
    handlePeriod(periodKeys[0], cityOpt, mode);
  };

  const handlePeriod = (period, cityOpt = city, modeOpt = mode) => {
    setPeriodSelect(period);

    const installmentKeys = Object?.keys(
      course[cityOpt][modeOpt][period] || {}
    );
    setInstallmentOptions(installmentKeys);
    handleInstallment(installmentKeys[installmentKeys.length - 1]);
  };

  const handleInstallment = (option) => {
    setInstallmentSelect(option);
  };

  const getTotalDiscount = () => {
    return course?.[city]?.[mode]?.[period]?.[installment]?.discount;
  };

  const getTotalValue = () => {
    return course?.[city]?.[mode]?.[period]?.[installment]?.value;
  };

  const currencyFormat = (num) => {
    return num
      ?.toFixed(2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  };

  const handleClick = () => {
    navigate("/register", {
      state: { course, city, period, installment, mode, value: getTotalValue() },
    });
  };

  const camelToPascalWithSpaces = (str) => {
    return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };

  return (
    <Layout>
      <div className="px-5 md:px-20 py-10">
        <div className="flex md:flex-nowrap">
          <div className="w-full md:w-1/2">
            <div className="text-4xl text-primary font-bold mb-4 md:whitespace-nowrap raleway">
              Escolha seu turno e modalidade
            </div>

            <select
              className="rounded-full border-primary font-bold mt-5 mb-3 py-0"
              value={courseSelect}
              onChange={(e) => handleCourse(e.target.value)}
            >
              <option value="revisaenem">Revisa ENEM</option>
              <option value="revisaufsc">Revisa UFSC</option>
            </select>

            <div className="text-xl mt-3 text-gray-600 font-semibold md:pr-20 italic">
              {course.short}
            </div>

            <div className="text-xl mt-3 text-gray-600 font-semibold md:pr-20 italic">
              Início: {new Date(course.initDate).toLocaleDateString("pt-BR")}{" "}
              <br />
              {/*               Término: {new Date(course.endDate).toLocaleDateString('pt-BR')} */}
            </div>

            <div>
              {/* city */}
              <select
                className="rounded-full border-primary font-bold mt-5 mb-3 py-0 mr-3"
                placeholder="Cidade"
                onChange={(e) => handleCity(e.target.value)}
                value={city}
              >
                <option value="floripa">Floripa</option>
                {courseSelect == "revisaufsc" && (
                  <option value="palhoca">Palhoça</option>
                )}
              </select>

              {/* mode */}
              <select
                className="rounded-full border-primary font-bold mt-5 mb-3 py-0 mr-3"
                placeholder="Modo"
                onChange={(e) => handleMode(e.target.value)}
                value={mode}
              >
                {modeOptions.map((mode, index) => (
                  <option key={index} value={mode}>
                    {camelToPascalWithSpaces(mode)}
                  </option>
                ))}
              </select>

              {/* period */}
              <select
                className="rounded-full border-primary font-bold mt-5 mb-3 py-0 mr-3"
                placeholder="Turno"
                onChange={(e) => handlePeriod(e.target.value)}
                value={period}
              >
                {periodOptions.map((period, index) => (
                  <option key={index} value={period}>
                    {camelToPascalWithSpaces(period)}
                  </option>
                ))}
              </select>
            </div>

            {/* select parcelas */}
            <div className="flex items-center gap-4 pt-3">
              <div className="">
                <div className="font-bold text-xl">Parcelas</div>
                <div>
                  <select
                    className="rounded-full border-primary font-bold py-0"
                    value={installment}
                    onChange={(e) => handleInstallment(e.target.value)}
                  >
                    {installmentOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <span className="font-semibold text-xl text-accent">R$</span>
                <span className="font-bold text-8xl text-primary">
                  {currencyFormat(
                    course?.[city]?.[mode]?.[period]?.[installment]?.value
                  )}
                </span>
              </div>
            </div>

            {getTotalDiscount() > 0 && (
              <div className="text-2xl text-gray-600 font-semibold">
                <span className="text-accent pl-2 font-bold">
                  {getTotalDiscount()}%{" "}
                </span>
                de desconto!
              </div>
            )}

            <button
              onClick={handleClick}
              disabled={course.name === "Intensivo"}
              className="w-full md:w-auto bottom-3 mx-auto mt-10 bg-accent text-primary-400 py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden
            disabled:border disabled:border-accent disabled:bg-white disabled:opacity-50 disabled:cursor-default"
            >
              Matricular agora!
            </button>

            {courseSelect === 'smart' && <div className="w-full py-10">
              <div className="text-4xl text-primary font-bold md:leading-8">
                Extensivo Smart 2024
                </div>
                <div className="text-2xl font-semibold mt-5">
                O Curso ideal para você que procura um reforço para o ensino médio e se preparar para o ENEM e Vestibulares:
                </div>
                <div className="text-2xl font-semibold">
                  <ul className="list-disc pl-0 text-left">
                    <li>34 semanas, 4 dias por semana, 4 aulas por dia;</li>
                    <li>Unidade na melhor localização do Centro;</li>
                    <li>Turmas no turno Vespertino e Noturno;</li>
                    <li>Equipe qualificada para esta nova modalidade;</li>
                    <li>Monitorias de todas as disciplinas;</li>
                    <li>Plataforma com aulas gravadas para ajudar na fixação do conteúdo.</li>
                  </ul>
                </div>
              </div>
            }
            {courseSelect === 'extensivo' && <div className="w-full py-10">
              <div className="text-4xl text-primary font-bold md:leading-8">
                Extensivo 2024
                </div>
                <div className="text-2xl font-semibold mt-5">
                Ideal para quem quer uma preparação mais completa para se preparar para os melhores vestibulares do Brasil, em um imersão total de conteúdo.
                </div>
                <div className="text-2xl font-semibold">
                  <ul className="list-disc pl-0 text-left">
                    <li>11 meses,  28 aulas semanais de todas as matérias.</li>
                    <li>Carga horária equilibrada que aborda todo o conteúdo das principais provas.</li>
                    <li>Aulas de redação, filosofia e sociologia já inclusas na grade.</li>
                    <li>Mais tempo com o professor em sala de aula para trabalhar os conteúdos mais básicos e aprofundados, inclusive com resolução de questões.</li>
                    <li>Equilíbrio na distribuição das aulas para ter tempo de organizar os estudos fora de sala de aula.</li>
                  </ul>
                </div>
              </div>
            }
            {courseSelect === 'semiextensivo' && <div className="w-full py-10">
              <div className="text-4xl text-primary font-bold md:leading-8">
                Semiextensivo 2024
                </div>
                <div className="text-2xl font-semibold mt-5">
                Feito para os vestibulandos que não têm tempo a perder, esse curso oferece preparação eficiente e mais compacta para você conquistar sua vaga.
                </div>
                <div className="text-2xl font-semibold">
                  <ul className="list-disc pl-0 text-left">
                    <li>5 meses,  30 aulas semanais de todas as matérias.</li>
                    <li>Carga horária equilibrada que aborda todo o conteúdo das principais provas.</li>
                    <li>Aulas de inglês ou espanhol, filosofia e sociologia na grade.</li>
                    <li>Plataforma com aulas gravadas para ajudar na fixação dos assuntos abordados em sala</li>
                    <li>Metodologia que otimiza a aprendizagem.</li>
                  </ul>
                </div>
              </div>
            }
            {courseSelect === 'semiextensivosmart' && <div className="w-full py-10">
              <div className="text-4xl text-primary font-bold md:leading-8">
                Semiextensivo Smart
                </div>
                <div className="text-2xl font-semibold mt-5">
                Feito para os vestibulandos que não têm tempo a perder, esse curso oferece preparação eficiente e mais compacta para você conquistar sua vaga.
                </div>
                <div className="text-2xl font-semibold">
                  <ul className="list-disc pl-0 text-left">
                    <li>5 meses, 4 aulas por dia, de segunda à quinta-feira.</li>
                    <li>Carga horária equilibrada que aborda todo o conteúdo das principais provas.</li>
                    <li>Aulas de inglês ou espanhol, filosofia e sociologia, atualidades e obras literárias oferecidas gravadas.</li>
                    {/* <li>Plataforma com aulas gravadas para ajudar na fixação dos assuntos abordados em sala</li> */}
                    <li>Metodologia que otimiza a aprendizagem.</li>
                  </ul>
                </div>
              </div>
            }
            {courseSelect === 'revisaenem' && <div className="w-full py-10">
              <div className="text-4xl text-primary font-bold md:leading-8">
                Revisa ENEM
                </div>
                <div className="text-2xl font-semibold mt-5">
                Feito para os estudantes que precisam de uma revisão aprofundada e prática dos conteúdos abordados no ENEM de maneira assertiva e intensa! 
                </div>
                <div className="text-2xl font-semibold">
                  <ul className="list-disc pl-0 text-left">
                    <li>Turno Matutino;</li>
                    <li>5 semanas, 29 aulas semanais, de 45 minutos cada, de todas as matérias;</li>
                    <li>Aula com estratégia de prova para entender a melhor forma de resolução da prova do ENEM;</li>
                    <li>Diversas monitorias a sua disposição;</li>
                    <li>Plataforma com aula gravada para ajudar na fixação dos assuntos estudados.</li>
                  </ul>
                </div>
              </div>
            }
            {courseSelect === 'revisaufsc' && <div className="w-full py-10">
              <div className="text-4xl text-primary font-bold md:leading-8">
                Revisa UFSC
                </div>
                <div className="text-2xl font-semibold mt-5">
                Feito para os estudantes que precisam recordar os conteúdos mais cobrados de maneira assertiva e intensa! 
                </div>
                  {period === 'matutino' &&
                  <div className="text-2xl font-semibold">
                    <ul className="list-disc pl-0 text-left">
                      <li>- 4 semanas, 29 aulas semanais, de 45 minutos cada, de todas as matérias.</li>
                      <li>- Aulas de inglês ou espanhol e filosofia e sociologia na grade.</li>
                      <li>- Aula com estratégia de prova para entender a melhor forma de resolução do vestibular do vestibular da UFSC.</li>
                      <li>- Diversas monitorias a sua disposição;</li>
                      <li>- Plataforma com aula gravada para ajudar na fixação dos assuntos estudados."</li>
                    </ul>
                  </div>

                  }
                  {period !== 'matutino' &&
                  <div className="text-2xl font-semibold">
                    <ul className="list-disc pl-0 text-left">
                      <li>- 4 semanas, 16 aulas semanais, de 45 minutos cada, de todas as matérias.</li>
                      <li>- Aula com estratégia de prova para entender a melhor forma de resolução do vestibular do vestibular da UFSC.</li>
                      <li>- Diversas monitorias a sua disposição;</li>
                      <li>- Plataforma com aula gravada para ajudar na fixação dos assuntos estudados.</li>
                    </ul>
                  </div>
                  } 

                
              </div>
            }


            
{/*             {period === 'matutino' &&
            <div className="w-full py-10">
              <div className="text-4xl text-primary font-bold md:leading-8">
              Intensivo Matutino 2023
              </div>
              <div className="text-2xl font-semibold mt-5">
                Feito para os estudantes que precisam recordar os conteúdos mais cobrados de maneira assertiva e intensa! 
              </div>
              <div className="text-2xl font-semibold">
                <ul className="list-disc pl-0 text-left">
                  <li>4 semanas, 27 aulas semanais, de 45 minutos cada, de todas as matérias.</li>
                  <li>Aulas de inglês ou espanhol e filosofia e sociologia na grade.</li>
                  <li>Aula com estratégia de prova para entender melhor a resolução dos vestibulares (UFSC e UDESC).</li>
                  <li>Diversas monitorias à sua disposição.</li>
                  <li>Plataforma com aula gravada para ajudar na fixação dos assuntos estudados.</li>
                </ul>
              </div>

            </div>
            }
            {period === 'vespertino' &&
            <div className="w-full py-10">
              <div className="text-4xl text-primary font-bold md:leading-8">
              Intensivo Vespertino 2023
              </div>
              <div className="text-2xl font-semibold  mt-5">
                Feito para os estudantes que precisam recordar os conteúdos mais cobrados de maneira assertiva e intensa! 
              </div>
              <div className="text-2xl font-semibold">
                <ul className="list-disc pl-0 text-left">
                  <li>4 semanas, 16 aulas semanais, de 45 minutos cada.</li>
                  <li>Aula com estratégia de prova para entender melhor a resolução dos vestibulares (UFSC e UDESC).</li>
                  <li>Diversas monitorias à sua disposição.</li>
                  <li>Plataforma com aula gravada para ajudar na fixação dos assuntos estudados.</li>
                </ul>
              </div>
            </div>
            }            
            {period === 'noturno' &&
            <div className="w-full py-10">
              <div className="text-4xl text-primary font-bold md:leading-8">
              Intensivo Noturno 2023
              </div>
              <div className="text-2xl font-semibold mt-5">
                Feito para os estudantes que precisam recordar os conteúdos mais cobrados de maneira assertiva e intensa! 
              </div>
              <div className="text-2xl font-semibold">
                <ul className="list-disc pl-0 text-left">
                  <li>4 semanas, 16 aulas semanais, de 45 minutos cada.</li>
                  <li>Aula com estratégia de prova para entender melhor a resolução dos vestibulares (UFSC e UDESC).</li>
                  <li>Diversas monitorias à sua disposição.</li>
                  <li>Plataforma com aula gravada para ajudar na fixação dos assuntos estudados.</li>
                </ul>
              </div>
            </div>
            } */}
            
          </div>

          <div className="w-full md:w-1/2 hidden md:block transition-all">
            {course.name.toLowerCase() === "extensivo" && (
              <img
                className="w-full md:-mt-10"
                src={extensivoSvg}
                alt="extensivo"
              />
            )}
            {course.name.toLowerCase() === "intensivo ufsc" && (
              <img
                className="w-full md:-mt-10"
                src={intensivoSvg}
                alt="intensivo"
              />
            )}
            {course.name.toLowerCase() === "semiextensivo smart" && (
              <img className="w-full md:-mt-10" src={smartSvg} alt="Semiextensivo Smart" />
            )}
            {course.name.toLowerCase() === "semiextensivo" && (
              <img
                className="w-full md:-mt-10"
                src={semiextensivoSvg}
                alt="semiextensivo"
              />
            )}
          </div>
        </div>
      </div>
      <Content
        title={
          "Lembre-se que somos a melhor preparação para conquistar seu futuro!"
        }
        desc={
          "Aqui pensamos e preparamos cada passo da sua trajetória, pensando no melhor aproveitamento para você chegar ao seu objetivo"
        }
        course={course?.name}
      ></Content>
      <Location floripa={true}></Location>
    </Layout>
  );
};

export default Pricing;
