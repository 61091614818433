import Axios from "axios";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import InputMask from "react-input-mask";

import Layout from "../components/Layout";
import register from "../images/svg/register.svg";

const isBrowser = typeof window !== "undefined";

const Register = ({ location }) => {
  /* const api = "https://pf-api.prouniversidade.com.br"; */
  //const api = 'http://localhost/pro-floripa-new/pf-api';

  const data = useStaticQuery(
    graphql`
      query {
        docs: allFile(filter: { dir: { regex: "/data/documents/" } }) {
          nodes {
            name
            publicURL
          }
        }
      }
    `
  );

  const { course, period, city, mode, user, installment, value } =
    location.state || {};

  if (!course && isBrowser) {
    navigate("/pricing");
  }

  const [state, setState] = useState({
    name: user?.name,
    email: user?.email,
    phone: user?.phone,
    plan: `${course?.name} - ${city} - ${period} - ${mode}`,
    parcelas: installment,
    valor: value,
    cpf: "",
    cep: "",
    number: "",
    city: "",
    complement: "",
    address: "",
    district: "",
    uf: "",
    coupon: "",

    nascimento: "",
    nomeSocial: "",
    rg: "",
    rgEmissor: "",
    responsavelFinanceiro: {
      name: "",
      cpf: "",
      rg: "",
      phone: "",
      email: "",
      relationship: "",
    },
    contatoEmergencia: {
      name: "",
      phone: "",
      relationship: "",
    },
    ensinoMedio: {
      has: false,
      school: "",
      city: "",
      year: "",
    },
    preVestibular: {
      has: false,
      name: "",
    },
    instituicoesPretencao: "",
    cursosPretencao: "",
    prestaraEnem: false,
    medicamento: {
      has: false,
      name: "",
    },
    necessidadesEspeciais: {
      has: false,
      name: "",
    },
    alergias: {
      has: false,
      name: "",
    },
    comoConheceu: "",
    camiseta: {
      size: "",
      babylook: "",
    },
    origin: "Cursinhopro.com.br"
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    const splitedName = event.target.name.split(".");
    if (splitedName.length > 1) {
      setState({
        ...state,
        [splitedName[0]]: {
          ...state[splitedName[0]],
          [splitedName[1]]: value
        },
      });
    } else {
      setState({
        ...state,
        [event.target.name]: value
      });
    }

    event.target.name === "cep" && fetchCep();
  };

  const getCourseDocumentUrl = () => {
    return course?.docs?.[city]?.[mode]?.[period];
  };

  const fetchCep = async () => {
    if (state.cep?.length === 9) {
      Axios.get(
        `https://viacep.com.br/ws/${state.cep.replace("-", "").trim()}/json/`
      )
        .then((data) => {
          setState({
            ...state,
            address: data.data.logradouro,
            district: data.data.bairro,
            city: data.data.localidade,
            uf: data.data.uf,
            complement: data.data.complemento,
          });
        })
        .catch((error) => console.log(error));
    }
  };

  const handleOnSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://formspree.io/f/mvgorapv', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(state)
      });

      if (response.ok) {
        setLoading(false);
        navigate("/thanks");
      } else {
        console.error('Erro ao enviar o formulário', response);
      }
    } catch (err) {
      console.error('Erro ao enviar o formulário', err);
      setLoading(false);
    }


/*     Axios.post(`${api}/buy-bo`, state)
      .then((res) => {
        navigate("/thanks");
      })
      .catch((err) => {
        console.log("Erro ao enviar formulário", err);
        setLoading(false);
      }); */
  };

  return (
    <Layout>
      <div className="px-5 md:px-20 py-10 mx-auto overflow-y-auto">
        <form onSubmit={handleOnSubmit}>
          <div className="grid md:grid-cols-2 gap-x-5">
            <div className="w-full col-span-1">
              <div className="text-4xl text-primary font-bold mb-8 md:pr-30 leading-8">
                Oba! Vamos começar sua matrícula...
              </div>
              <div className="mb-5">
                <InputMask
                  name="name"
                  required
                  value={state.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  type="text"
                  placeholder="Nome completo"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="email"
                  required
                  value={state.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  type="email"
                  placeholder="E-mail"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="phone"
                  required
                  mask="(99) 99999-9999"
                  maskChar={null}
                  value={state.phone}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  type="text"
                  placeholder="Celular"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="cpf"
                  type="text"
                  required
                  mask="999.999.999-99"
                  maskChar={null}
                  value={state.cpf}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="CPF"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="rg"
                  type="text"
                  required
                  mask="999999999999"
                  maskChar={null}
                  value={state.rg}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="RG"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="rgEmissor"
                  type="text"
                  required
                  value={state.rgEmissor}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="Órgão expedidor"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="nascimento"
                  type="text"
                  required
                  mask="99/99/9999"
                  maskChar={null}
                  value={state.nascimento}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="Nascimento"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="nomeSocial"
                  type="text"
                  value={state.nomeSocial}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="Nome social (se tiver)"
                />
              </div>
            </div>
            {/* col 2 */}
            <div className="w-full col-span-1">
              <img
                className="hidden md:block mx-auto -mt-10"
                src={register}
                alt="registro"
              />
            </div>
            <div className="w-full col-span-1">
              <div className="mb-5">
                <InputMask
                  name="cep"
                  type="text"
                  required
                  mask="99999-999"
                  maskChar={null}
                  value={state.cep}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="CEP"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="number"
                  type="text"
                  required
                  mask="999999999"
                  maskChar={null}
                  value={state.number}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="Número"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="city"
                  type="text"
                  required
                  value={state.city}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="Cidade"
                />
              </div>
            </div>
            <div className="w-full col-span-1">
              <div className="mb-5">
                <InputMask
                  name="address"
                  type="text"
                  required
                  value={state.address}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="Endereço"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="district"
                  type="text"
                  required
                  value={state.district}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="Bairro"
                />
              </div>
              <div className="mb-5">
                <InputMask
                  name="uf"
                  type="text"
                  required
                  mask="aa"
                  maskChar={null}
                  value={state.uf}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                  placeholder="UF"
                />
              </div>
            </div>
          </div>
          <div className="mb-5">
            <InputMask
              name="complement"
              type="text"
              value={state.complement}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
              placeholder="Complemento / Observação"
            />
          </div>
          {/*  ==================================================== new inputs ==================================================== */}
          {/* responsável financeiro */}
          <h1 className="text-3xl mb-3">Dados do responsável financeiro</h1>
          <div class="flex flex-wrap">
            <div class="mb-5 w-full sm:w-1/2 md:w-1/6">
              <InputMask
                name="responsavelFinanceiro.name"
                type="text"
                value={state.responsavelFinanceiro.name}
                onChange={handleInputChange}
                class="w-full px-3 py-2 border rounded-2xl border-primary"
                placeholder="Nome do responsável"
              />
            </div>
            <div class="mb-5 w-full sm:w-1/2 md:w-1/6">
              <InputMask
                name="responsavelFinanceiro.cpf"
                type="text"
                mask="999.999.999-99"
                maskChar={null}
                value={state.responsavelFinanceiro.cpf}
                onChange={handleInputChange}
                class="w-full px-3 py-2 border rounded-2xl border-primary"
                placeholder="CPF"
              />
            </div>
            <div class="mb-5 w-full sm:w-1/2 md:w-1/6">
              <InputMask
                name="responsavelFinanceiro.relationship"
                type="text"
                value={state.responsavelFinanceiro.relationship}
                onChange={handleInputChange}
                class="w-full px-3 py-2 border rounded-2xl border-primary"
                placeholder="Relação (ex: pai, mãe, etc)"
              />
            </div>
            <div class="mb-5 w-full sm:w-1/2 md:w-1/6">
              <InputMask
                name="responsavelFinanceiro.rg"
                type="text"
                mask="999999999999"
                maskChar={null}
                value={state.responsavelFinanceiro.rg}
                onChange={handleInputChange}
                class="w-full px-3 py-2 border rounded-2xl border-primary"
                placeholder="RG"
              />
            </div>
            <div class="mb-5 w-full sm:w-1/2 md:w-1/6">
              <InputMask
                name="responsavelFinanceiro.phone"
                mask="(99) 99999-9999"
                maskChar={null}
                value={state.responsavelFinanceiro.phone}
                onChange={handleInputChange}
                class="w-full px-3 py-2 border rounded-2xl border-primary"
                type="text"
                placeholder="Celular"
              />
            </div>
            <div class="mb-5 w-full sm:w-1/2 md:w-1/6">
              <InputMask
                name="responsavelFinanceiro.email"
                value={state.responsavelFinanceiro.email}
                onChange={handleInputChange}
                class="w-full px-3 py-2 border rounded-2xl border-primary"
                type="email"
                placeholder="E-mail"
              />
            </div>
          </div>



          {/* contato emergência */}
          <h1 className="text-3xl mb-3">Contato de emergência</h1>
          <div className="flex flex-wrap">
            <div className="mb-5 w-full sm:w-1/2 md:w-1/3">
              <InputMask
                name="contatoEmergencia.name"
                type="text"
                value={state.contatoEmergencia.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Nome do contato"
              />
            </div>
            <div className="mb-5 w-full sm:w-1/2 md:w-1/3">
              <InputMask
                name="contatoEmergencia.relationship"
                type="text"
                value={state.contatoEmergencia.relationship}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Relação (ex: pai, mãe, etc)"
              />
            </div>
            <div className="mb-5 w-full sm:w-1/2 md:w-1/3">
              <InputMask
                name="contatoEmergencia.phone"
                mask="(99) 99999-9999"
                maskChar={null}
                value={state.contatoEmergencia.phone}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                type="text"
                placeholder="Celular"
              />
            </div>
          </div>

          {/* Informações gerais */}
          <h1 className="text-3xl mb-3">Informações gerais</h1>
          {/* ensino medio */}
          <div className="flex space-x-5">
            <div className="mb-5 flex items-center">
              <InputMask
                className="mr-2"
                type="checkbox"
                checked={state.ensinoMedio.has}
                id="ensinoMedio"
                name="ensinoMedio.has"
                onChange={handleInputChange}
              />
              <label className="mr-3 cursor-pointer" htmlFor="ensinoMedio">
                Formado no ensino médio?
              </label>
            </div>
            <div className="mb-5 flex-1">
              <InputMask
                name="ensinoMedio.year"
                type="text"
                value={state.ensinoMedio.year}
                onChange={handleInputChange}
                mask="9999"
                maskChar={null}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Ano de conclusão ou previsão"
              />
            </div>
            <div className="mb-5 flex-1">
              <InputMask
                name="ensinoMedio.school"
                type="text"
                value={state.ensinoMedio.school}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Nome da instituição de ensino"
              />
            </div>
            <div className="mb-5 flex-1">
              <InputMask
                name="ensinoMedio.city"
                type="text"
                value={state.ensinoMedio.city}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Cidade da instituição de ensino"
              />
            </div>
          </div>

          {/* pre-vestibular */}
          <div className="flex space-x-5">
            <div className="mb-5 flex items-center">
              <InputMask
                className="mr-2"
                type="checkbox"
                checked={state.preVestibular.has}
                id="preVestibular"
                name="preVestibular.has"
                onChange={handleInputChange}
              />
              <label className="mr-3 cursor-pointer" htmlFor="preVestibular">
                Já fez pré-vestibular ou curso anteriormente?
              </label>
            </div>
            <div className="mb-5 flex-1">
              <InputMask
                name="preVestibular.name"
                type="text"
                value={state.preVestibular.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Nome da instituição de ensino"
              />
            </div>
          </div>

          {/* enem */}
          <div className="flex space-x-5">
            <div className="mb-5 flex items-center">
              <InputMask
                className="mr-2"
                type="checkbox"
                id="prestaraEnem"
                checked={state.prestaraEnem}
                name="prestaraEnem"
                onChange={handleInputChange}
              />
              <label className="mr-3 cursor-pointer" htmlFor="prestaraEnem">
                Irá prestar o ENEM?
              </label>
            </div>
            <div className="mb-5 flex-1">
              <InputMask
                name="cursosPretencao"
                type="text"
                value={state.cursosPretencao}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Cursos pretendidos"
              />
            </div>
            <div className="mb-5 flex-1">
              <InputMask
                name="instituicoesPretencao"
                type="text"
                value={state.instituicoesPretencao}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Instituições pretendidas"
              />
            </div>
          </div>

          {/* medicamentos */}
          <div className="flex space-x-5">
            <div className="mb-5 flex items-center">
              <InputMask
                className="mr-2"
                type="checkbox"
                id="medicamento"
                checked={state.medicamento.has}
                name="medicamento.has"
                onChange={handleInputChange}
              />
              <label className="mr-3 cursor-pointer" htmlFor="medicamento">
                Faz uso de algum medicamento controlado?
              </label>
            </div>
            <div className="mb-5 flex-1">
              <InputMask
                name="medicamento.name"
                type="text"
                value={state.medicamento.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Nome dos medicamentos"
              />
            </div>
          </div>

          {/* alergias */}
          <div className="flex space-x-5">
            <div className="mb-5 flex items-center">
              <InputMask
                className="mr-2"
                type="checkbox"
                id="alergias"
                checked={state.alergias.has}
                name="alergias.has"
                onChange={handleInputChange}
              />
              <label className="mr-3 cursor-pointer" htmlFor="alergias">
                Possui alergias?
              </label>
            </div>
            <div className="mb-5 flex-1">
              <InputMask
                name="alergias.name"
                type="text"
                value={state.alergias.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Nome das alergias"
              />
            </div>
          </div>

          {/* necessidades especiais */}
          <div className="flex space-x-5">
            <div className="mb-5 flex items-center">
              <InputMask
                className="mr-2"
                type="checkbox"
                id="necessidadesEspeciais.has"
                checked={state.necessidadesEspeciais.has}
                name="necessidadesEspeciais.has"
                onChange={handleInputChange}
              />
              <label
                className="mr-3 cursor-pointer"
                htmlFor="necessidadesEspeciais.has"
              >
                Pessoa com necessidades educacionais especiais?
              </label>
            </div>
            <div className="mb-5 flex-1">
              <InputMask
                name="necessidadesEspeciais.name"
                type="text"
                value={state.necessidadesEspeciais.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Nome das necessidades educacionais especiais"
              />
            </div>
          </div>

          {/* como soube */}
          <div className="flex space-x-5">
            <div className="mb-5 flex-1">
              <InputMask
                name="comoConheceu"
                type="text"
                value={state.comoConheceu}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                placeholder="Como ficou sabendo do curso?"
              />
            </div>
            <div className="mb-5 flex-1">
              <select
                name="camiseta.size"
                value={state.camiseta.size}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
              >
                <option value="">Tamanho da camiseta</option>
                <option value="PP">PP</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="G">G</option>
                <option value="GG">GG</option>
              </select>
            </div>
            <div className="mb-5 flex items-center">
              <InputMask
                className="mr-2"
                type="checkbox"
                id="camiseta"
                checked={state.camiseta.babylook}
                name="camiseta.babylook"
                onChange={handleInputChange}
              />
              <label className="mr-3 cursor-pointer" htmlFor="camiseta">
                Modelo Babylook?
              </label>
            </div>
          </div>
          {/* ==================================================== end new ========================================================= */}
          <div className="mb-5">
            <InputMask
              name="coupon"
              type="text"
              value={state.coupon}
              onChange={handleInputChange}
              className="w-full px-3 py-2 mt-5 mb-2 border rounded-2xl border-green-500"
              placeholder="Cupom de desconto"
            />
          </div>
          <div>
            <InputMask
              className="mr-2"
              type="checkbox"
              required
              id="document"
            />
            <label className="font-bold" htmlFor="document">
              Declaro que li e aceito
              <a
                href={getCourseDocumentUrl()}
                target="_blank"
                rel="noreferrer"
                className="text-primary ml-2"
              >
                os termos de matrícula
              </a>
            </label>
          </div>

          {!loading && (
            <button className="w-full bottom-3 mx-auto mt-10 bg-accent text-primary-400 py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden">
              Finalizar cadastro
            </button>
          )}

          {loading && (
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-15 w-15 border-b-2 border-primary"></div>
            </div>
          )}
        </form>
      </div>
    </Layout>
  );
};

export default Register;
