import axios from 'axios';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { useModal } from 'react-hooks-use-modal';

import modal from '../../../images/svg/modal.svg';

import InputMask from "react-input-mask";

const ModalRegister = ({ label, disabled, course }) => {

  const api = 'https://pf-api.prouniversidade.com.br';
  /*  const api = 'http://localhost/pro-floripa/api'; */

  const [Modal, open, close] = useModal('modal', {
    preventScroll: true,
    closeOnOverlayClick: false
  });

  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
  })

  const handleInputChange = event => {
    state[event.target.name] = event.target.value;
    setState({ ...state })
  }

  const createContact = async (name, email, phone) => {
    const contactData = {
      name,
      email,
      phone,
    };

    try {
      const response = await axios.post(`https://api.pipedrive.com/v1/persons?api_token=25103e88b2dd2336b72be7c30bec8af7b10909c2`, contactData);
      return response.data.data.id; // Retorna o ID do contato criado
    } catch (error) {
      console.error("Erro ao criar contato no Pipedrive:", error.response.data);
      return null;
    }
  };

  const createDeal = async (title, value, currency, personId) => {
    const dealData = {
      title, // O título do negócio agora é passado como um parâmetro
      value,
      currency,
      person_id: personId,
      stage_id: 1, // ID da etapa do funil onde o negócio será criado
    };

    try {
      const response = await axios.post(`https://api.pipedrive.com/v1/deals?api_token=25103e88b2dd2336b72be7c30bec8af7b10909c2`, dealData);
      console.log("Negócio criado com sucesso:", response.data);
    } catch (error) {
      console.error("Erro ao criar negócio no Pipedrive:", error.response.data);
    }
  };

  const handleOnSubmit = async event => {
    event.preventDefault();

    const personId = await createContact(state.name, state.email, state.phone);
    if (personId) {
      await createDeal(state.name, 0, "USD", personId); // O nome do negócio agora é o mesmo que o nome do formulário
      navigate(`/pricing?p=${course}`, { state: { ...state } });
    } else {
      console.error("Erro ao criar contato, não foi possível criar o negócio.");
    }
  }

  return (
    <div id="modal">
      <Modal>
        <div className="mx-5  bg-white shadow-md py-10 px-10 relative rounded-xl">
          <img className="mx-auto hidden md:block md:h-30" src={modal} alt="Cadastro" />
          <div className="text-2xl text-gray-600 text-center py-5">
            Para continuar, preencha seus dados.
          </div>

          <form className="form md:w-96" onSubmit={handleOnSubmit}>
            <div className="flex flex-col pb-3">
              <label className="pb-2 font-semibold" htmlFor="name">Nome completo:</label>
              <input
                id="name"
                name="name"
                value={state.name}
                onChange={handleInputChange}
                placeholder="Digite seu nome" className="rounded border-primary" type="text" required />
            </div>
            <div className="flex flex-col pb-3">
              <label className="pb-2 font-semibold" htmlFor="email">E-mail:</label>
              <input
                id="email"
                name="email"
                value={state.email}
                onChange={handleInputChange}
                placeholder="Digite seu email" className="rounded border-primary" type="email" required />
            </div>
            <div className="flex flex-col pb-3">
              <label className="pb-2 font-semibold" htmlFor="phone">Celular:</label>
              <InputMask
                mask="(99) 99999-9999"
                maskChar={null}
                value={state.phone}
                onChange={handleInputChange}
                id="phone"
                name="phone"
                placeholder="Digite seu número de celular" className="rounded border-primary" type="text" />
            </div>

            <button type="submit" className='w-full bottom-3 mx-auto mt-5 bg-accent text-primary-400 py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden'>
              Continuar
            </button>
          </form>

          <button className="rounded-full bg-primary text-accent px-2 font-semibold text-2xl absolute -top-3 -right-3" onClick={close}>x</button>
        </div>
      </Modal>

      <button
        disabled={disabled}
        onClick={open}
        className='bottom-3 mx-auto mt-5 bg-accent text-primary-400 py-2 px-15 rounded-2xl text-xl font-bold overflow-hidden
      disabled:border disabled:border-accent disabled:bg-white disabled:opacity-50 disabled:cursor-default'>
        {label}
      </button>
    </div>
  )
}

export default ModalRegister
