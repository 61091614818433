import { graphql } from 'gatsby';
import React from 'react';

import extensivo from '../../images/svg/courses/extensivo-xl.svg';
import intensivo from '../../images/svg/courses/intensivo-xl.svg';
import semiextensivo from '../../images/svg/courses/semiextensivo-xl.svg';
import smart from '../../images/svg/courses/smart-xl.svg';
import Layout from '../Layout';
import ModalRegister from '../Shared/Modal';
import Enroll from './Enroll';
import Information from './Information';
import Products from './Products';
import Students from './Students';
import Values from './Values';
import { navigate } from 'gatsby';

const Course = ({ data }) => {

  const { markdownRemark } = data
  const { name, description, students, initDate, endDate } = markdownRemark.frontmatter;

  const handler =() => {
    navigate(`/pricing?p=${name.toLowerCase().replace(/\s+/g, '')}`);
  }

  return (
    <Layout>
      <div className="container px-5 md:px-20 py-10">
        <div className="flex flex-wrap md:flex-nowrap gap-10">
          <div className="w-full md:w-1/2">
            <div className="text-primary text-4xl font-bold">{name}</div>
              <span className="rounded-full bg-accent text-primary-400 text-sm font-semibold px-4 py-1">Matrículas abertas</span>
            <div className="text-gray-600 text-3xl font-semibold mb-4 mt-7 leading-7">{description}</div>

            {initDate && (
              <div className="text-xl mt-3 text-gray-600 font-semibold md:pr-20 italic">
                Início: {new Date(initDate).toLocaleDateString('pt-BR')} {/* - Término: {new Date(endDate).toLocaleDateString('pt-BR')} */}
              </div>
            )}

            <button
                  onClick={handler}
                    className='bottom-3 mx-auto mt-5 bg-accent text-primary-400 py-2 px-15 rounded-2xl text-xl font-bold overflow-hidden
                  disabled:border disabled:border-accent disabled:bg-white disabled:opacity-50 disabled:cursor-default'>
                    Matricular agora!
                  </button>

            {/* <ModalRegister label={'Matricular agora!'} course={name.toLowerCase().replace(/\s+/g, '')}></ModalRegister> */}
          </div>
          <div className="w-full md:w-1/2">
            {name.toLowerCase() === 'extensivo' && <img className="w-full md:-mt-10" src={extensivo} alt="Extensivo" />}
            {name.toLowerCase() === 'intensivo' && <img className="w-full md:-mt-10" src={intensivo} alt="intensivo" />}
            {name.toLowerCase() === 'semiextensivo' && <img className="w-full md:-mt-10" src={semiextensivo} alt="semiextensivo" />}
            {name.toLowerCase() === 'extensivo smart' && <img className="w-full md:-mt-10" src={smart} alt="Smart" />}
            {name.toLowerCase() === 'revisaenem' && <img className="w-full md:-mt-10" src={intensivo} alt="revisaenem" />}
            {name.toLowerCase() === 'revisaufsc' && <img className="w-full md:-mt-10" src={semiextensivo} alt="revisaufsc" />}
          </div>
        </div>



      </div>
      <Values course={name.toLowerCase().replace(/\s+/g, '')}></Values>
      <Products course={name.toLowerCase().replace(/\s+/g, '')}></Products>
      {students?.length > 0 &&
        <Students students={students}></Students>
      }
      <Enroll></Enroll>
    </Layout>
  )
}

export default Course

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      frontmatter {
        name,
        description,
        information,
        initDate,
        endDate,
        pricing {
          total,
          installments {
            option,
            value
          }
        },
        students {
          name,
          course,
          college,
          photo
        }
      },
      html
    }
  }
`;
