import '../style.css';

import Axios from 'axios';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import file from '../../../images/svg/file.svg';
import upload from '../../../images/svg/upload.svg';


const Course = ({ area }) => {

  const api = 'https://pf-api.prouniversidade.com.br';
  // const api = 'http://localhost/pro-floripa/api';

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disciplineState, setDisciplineState] = useState([]);
  const [hours, setHours] = useState([]);

  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    hometown: "",
    formation: "",
    college: "",
    experience: "",
    file: "",
    curriculum: "",
  })

  const [fileState, setFileState] = useState({
    name: "",
    data: "",
    size: "",
    type: "",
    extension: "",
  })

  useEffect(() => {
    updateDisabled();
  }, [state, disciplineState, hours, fileState]);

  const handleInputChange = (event) => {
    state[event.target.name] = event.target.value;
    setState({ ...state });
  }

  const updateDisabled = (meth = null) => {
    meth && console.log(meth, formIsValid());
    setDisabled(formIsValid() ? false : true);
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const base64 = await convertBase64(file);
      setFileState({
        name: file.name,
        data: base64,
        size: file.size,
        type: file.type,
        extension: file.name.split('.').pop(),
      })

    } else {

      setFileState({
        name: "",
        data: "",
        size: "",
        type: "",
        extension: "",
      })
    }
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const formIsValid = () => {
    return (
      fileState.data && state.name && state.phone && state.email && state.hometown && state.formation &&
      state.college && state.experience && disciplineState.length && hours.length
    )
  }

  const handleDisciplines = (disc) => {
    let selected = disciplineState;
    let find = selected.indexOf(disc)

    if (find > -1) {
      selected.splice(find, 1)
    } else {
      selected.push(disc)
    }

    setDisciplineState([...selected])
  }

  const handleHours = (hour) => {
    let selected = hours;
    let find = selected.indexOf(hour)

    if (find > -1) {
      selected.splice(find, 1)
    } else {
      selected.push(hour)
    }

    setHours([...selected])
  }

  const handleOnSubmit = async event => {
    event.preventDefault();
  
    if (formIsValid()) {
      setLoading(true);
      let areaNumber = 1; // Cursinho padrão
  
      if (area === 'aulaParticular') areaNumber = 2;
      if (area === 'coordenacao') areaNumber = 3;
  
      try {
        const response = await fetch('https://formspree.io/f/mvgorapv', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...state,
            area: areaNumber,
            file: fileState,
            disciplines: disciplineState,
            hours: hours
          })
        });
  
        if (response.ok) {
          navigate('/thanks-team');
        } else {
          console.error('Erro ao enviar o formulário', response);
          alert('Erro ao enviar formulário. Tente novamente em instantes');
        }
      } catch (err) {
        console.error('Erro ao enviar o formulário', err);
        alert('Erro ao enviar formulário. Tente novamente em instantes');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleOnSubmit}>
        <div className="grid md:grid-cols-2 gap-5">
          <div className="grid-item">
            <div className="mb-5">
              <input
                name="name"
                required
                value={state.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                type="text"
                placeholder="Nome completo" />
            </div>
            <div className="mb-5">
              <InputMask
                name="phone"
                required
                mask="(99) 99999-9999"
                maskChar={null}
                value={state.phone}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                type="text"
                placeholder="Celular" />
            </div>
            <div className="mb-5">
              <input
                name="formation"
                required
                value={state.formation}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                type="text"
                placeholder="Área de formação" />
            </div>
          </div>

          <div className="grid-item">
            <div className="mb-5">
              <input
                name="hometown"
                required
                value={state.hometown}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                type="text"
                placeholder="Naturalidade" />
            </div>
            <div className="mb-5">
              <input
                name="email"
                required
                value={state.email}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                type="email"
                placeholder="E-mail" />
            </div>
            <div className="mb-5">
              <input
                name="college"
                required
                value={state.college}
                onChange={handleInputChange}
                className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
                type="text"
                placeholder="Instituição onde estudou" />
            </div>
          </div>
        </div>

        {/* disciplines */}
        <div className="grid md:grid-cols-4">
          <div className="grid-item">
            <div className="mb-5">
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("bio")}
                className="mr-2" type="checkbox" name="disciplines" value="bio" />Biologia<br />
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("esp")}
                className="mr-2" type="checkbox" name="disciplines" value="esp" />Espanhol<br />
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("filo/soc")}
                className="mr-2" type="checkbox" name="disciplines" value="filo/soc" />Filosofia / Sociologia<br />
            </div>
          </div>
          <div className="grid-item">
            <div className="mb-5">
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("fsc")}
                className="mr-2" type="checkbox" name="disciplines" value="fsc" />Física<br />
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("geo")}
                className="mr-2" type="checkbox" name="disciplines" value="geo" />Geografia<br />
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("gram")}
                className="mr-2" type="checkbox" name="disciplines" value="gram" />Gramática<br />
            </div>
          </div>
          <div className="grid-item">
            <div className="mb-5">
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("hist")}
                className="mr-2" type="checkbox" name="disciplines" value="hist" />História<br />
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("ing")}
                className="mr-2" type="checkbox" name="disciplines" value="ing" />Inglês<br />
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("lit")}
                className="mr-2" type="checkbox" name="disciplines" value="lit" />Literatura<br />
            </div>
          </div>
          <div className="grid-item">
            <div className="mb-5">
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("mtm")}
                className="mr-2" type="checkbox" name="disciplines" value="mtm" />Matemática<br />
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("qmc")}
                className="mr-2" type="checkbox" name="disciplines" value="qmc" />Química<br />
              <input
                onChange={(event) => { handleDisciplines(event.target.value) }}
                selected={disciplineState.includes("red")}
                className="mr-2" type="checkbox" name="disciplines" value="red" />Redação<br />
            </div>
          </div>
        </div>

        <div className="mb-5">
          <input
            name="experience"
            required
            value={state.experience}
            onChange={handleInputChange}
            className="w-full px-3 py-2 mb-2 border rounded-2xl border-primary"
            type="text"
            placeholder="Três últimas experiências profissionais" />
        </div>

        {/* horarios */}
        <div className="grid md:grid-cols-3">
          <div className="grid-item">
            <span className="font-bold">Matutino</span>
            <div className="flex">
              <div className="mb-5 w-full md:w-1/2">
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("8h")}
                  type="checkbox" name="hours" value="8h" />8h<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("8h45")}
                  type="checkbox" name="hours" value="8h45" />8h45<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("9h")}
                  type="checkbox" name="hours" value="9h" />9h<br />
              </div>
              <div className="mb-5 w-full md:w-1/2">
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("10h30")}
                  type="checkbox" name="hours" value="10h30" />10h30<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("11h45")}
                  type="checkbox" name="hours" value="11h45" />11h45<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("12h")}
                  type="checkbox" name="hours" value="12h" />12h<br />
              </div>
            </div>
          </div>

          <div className="grid-item">
            <span className="font-bold">Vespertino</span>
            <div className="flex">
              <div className="mb-5 w-full md:w-1/2">
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("13h30")}
                  type="checkbox" name="hours" value="13h30" />13h30<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("14h15")}
                  type="checkbox" name="hours" value="14h15" />14h15<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("15h")}
                  type="checkbox" name="hours" value="15h" />15h<br />
              </div>
              <div className="mb-5 w-full md:w-1/2">
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("16h")}
                  type="checkbox" name="hours" value="16h" />16h<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("16h45")}
                  type="checkbox" name="hours" value="16h45" />16h45<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("17h30")}
                  type="checkbox" name="hours" value="17h30" />17h30<br />
              </div>
            </div>
          </div>

          <div className="grid-item">
            <span className="font-bold">Noturno</span>
            <div className="flex">
              <div className="mb-5 w-full md:w-1/2">
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("18h30")}
                  type="checkbox" name="hours" value="18h30" />18h30<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("19h10")}
                  type="checkbox" name="hours" value="19h10" />19h10<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("19h50")}
                  type="checkbox" name="hours" value="19h50" />19h50<br />
              </div>
              <div className="mb-5 w-full md:w-1/2">
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("20h40")}
                  type="checkbox" name="hours" value="20h40" />20h40<br />
                <input className="mr-2"
                  onChange={(event) => { handleHours(event.target.value) }}
                  selected={hours.includes("21h20")}
                  type="checkbox" name="hours" value="21h20" />21h20<br />
              </div>
            </div>
          </div>
        </div>

        <div className="relative mt-3">
          <span className="font-bold">Curriculum <span>(PDF)</span></span>
          <input
            type="file"
            name="curriculum"
            id="curriculum"
            value={state.curriculum}
            onChange={handleFileChange}
            className='w-full h-full absolute top-0 right-0 bottom-0 left-0 cursor-pointer opacity-0' />

          <div className="file-dummy">
            {fileState.name !== "" &&
              <div className="w-full bg-accent border-dashed border-4 border-accent-700 text-center text-white font-bold py-5 ">
                <img className="mb-2 mx-auto h-15" src={file} alt="upload" /> <br />
                {fileState.name}
                <div className="small">{fileState.size}</div>
              </div>
            }
            {fileState.name === "" &&
              <div className="w-full bg-primary border-dashed border-4 border-primary-700 text-center text-white font-bold py-5">
                <img className="mb-2 mx-auto h-15" src={upload} alt="upload" /> <br />
                Arraste ou selecione o arquivo
              </div>
            }
          </div>
        </div>

        {!loading &&
          <button disabled={disabled} className='w-full bottom-3 mx-auto mt-10 bg-accent text-primary-400 py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden disabled:bg-gray-200 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none'>
            Finalizar cadastro
          </button>
        }

        {loading &&
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-15 w-15 border-b-2 border-primary"></div>
          </div>
        }
      </form >
    </div >
  )
}

export default Course
